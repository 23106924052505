module.exports = {
  selectLanguage: "Выберите свой язык",
  displayName: "русский",
  categories: "категории",
  read: "читать",
  price: "цена",
  lastUpdate: "Последнее обновление",
  free: "Теперь доступно бесплатно",
  Software: "Програмное обеспечение",
  "Machine Learning": "Mашинное обучение",
  Python: "Python",
  Azure: "Azure",
  "ARM Template": "ARM Template",
  Tags: "Теги",
  Home: "Главная страница",
  "API Management": "API Management",
  "Key Vault": "Key Vault",
  "Contact Form": "Форма обратной связи",
  "Cancel": "Отмена",
  "Submit": "Разместить",
  "Contact Message": "Мы будем признательны, если вы оставите нам отзыв на нашем веб-сайте.",
  "Flutter": "Flutter"
}
